import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './GlobalStyles.css';

const API_URL = process.env.REACT_APP_API_URL;

const DashboardCliente = () => {
  const [cliente, setCliente] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDadosCliente = async () => {
      const token = localStorage.getItem('token');

      if (!token) {
        navigate('/'); // Redireciona para o login se não houver token
        return;
      }

      try {
        // Faz uma requisição autenticada com o token para pegar os dados do cliente
        const response = await axios.get(`${API_URL}/api/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log('Dados do cliente:', response.data);
        setCliente(response.data);
      } catch (err) {
        console.error('Erro ao buscar dados do cliente:', err);
        localStorage.removeItem('token'); // Remove o token se inválido
        navigate('/');
      }
    };

    fetchDadosCliente();
  }, [navigate]);

  const handleEquipamentoClick = (equipamentoId) => {
    // Redireciona para a página de ordens do equipamento
    navigate(`/equipamento/${equipamentoId}/ordens`);
  };

  if (!cliente) {
    return <p>Carregando...</p>; // Exibe enquanto os dados não são carregados
  }

  return (
    <div className="container">
      <div className="box">
        <h1 className="title">{cliente.nome} {cliente.sobrenome}</h1>
        {cliente.equipamentos.length === 0 ? (
          <p className="no-equipment-message">
            Nenhum equipamento cadastrado no momento. Por favor, entre em contato com o responsável para cadastrá-los.
          </p>
        ) : (
          <>
            <h2 className="subtitle">Seus Equipamentos</h2>
            <ul className="equipment-list">
              {cliente.equipamentos.map((equipamento) => (
                <li key={equipamento.id} className="equipment-item">
                  <button
                    className="button"
                    onClick={() => handleEquipamentoClick(equipamento.id)}
                  >
                    {equipamento.tipo_equipamento}
                  </button>
                </li>
              ))}
            </ul>
          </>
        )}
        <button
          className="back-button"
          onClick={() => {
            localStorage.removeItem('token'); // Remove o token de autenticação (se aplicável)
            navigate('/'); // Redireciona para a tela inicial
          }}
        >
          Sair
        </button>
      </div>
    </div>
  );
};
  
export default DashboardCliente;