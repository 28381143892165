import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './DashboardAdmin.css'

const API_URL = `${process.env.REACT_APP_API_URL}`;

const Relatorios = () => {
  const [stats, setStats] = useState({
    totalClientes: 0,
    totalEquipamentos: 0,
    rendimentoPorEquipamento: {},
    tiposDeServicos: {},
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Exemplo de chamada para obter estatísticas
        const clientes = await axios.get(`${API_URL} /clientes/count`);
        const equipamentos = await axios.get(`${API_URL}/equipamentos/count`);
        //const rendimentoEquipamentos = await axios.get('/api/equipamentos/rendimento');
        //const tiposServicos = await axios.get('/api/servicos/tipos');

        setStats({
          totalClientes: clientes.data.count,
          totalEquipamentos: equipamentos.data.count,
        //Implementar  rendimentoPorEquipamento: rendimentoEquipamentos.data,
        //Implementar  tiposDeServicos: tiposServicos.data,
        });
      } catch (error) {
        console.error("Erro ao buscar dados de relatórios:", error);
      }
    };
    
    fetchData();
  }, []);

  return (
    <div className="relatorios-container">
      <h2>Relatórios de Atividades</h2>
      <div className="relatorio-card">
        <h3>Total de Clientes</h3>
        <p>{stats.totalClientes}</p>
      </div>
      <div className="relatorio-card">
        <h3>Total de Equipamentos</h3>
        <p>{stats.totalEquipamentos}</p>
      </div>
    </div>
  );
};

export default Relatorios;
