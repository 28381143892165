import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './GlobalStyles.css'; // arquivo CSS separado para melhor organização
import { jwtDecode } from 'jwt-decode';

const API_URL = process.env.REACT_APP_API_URL;

const isCPF = (value) => {
  // Verifica se a string contém apenas números e tem 11 dígitos
  const cleanedValue = value.replace(/\D/g, '');
  return cleanedValue.length === 11 && /^[0-9]+$/.test(cleanedValue);
};

const formatCPF = (value) => {
  // Remove tudo que não é número
  const cleanedValue = value.replace(/\D/g, '');
  // Aplica a máscara apenas se tiver 11 dígitos
  return cleanedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

const Login = () => {
  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log('Tentando login com:', { login, senha });

    setError('');

    try {
      if (!API_URL) {
        throw new Error('A URL da API não está configurada corretamente.');
      }

      // Verifica se é CPF ou e-mail
      let formattedLogin = login;
      if (isCPF(login)) {
        formattedLogin = login.replace(/\D/g, ''); // Remove os caracteres não numéricos
      }

      const response = await axios.post(`${API_URL}/api/login`, { login: formattedLogin, senha });
      const token = response.data.token;

      // Armazena o token no localStorage
      localStorage.setItem('token', token);

      // Decodifica o token para acessar o 'role'
      const decodedToken = jwtDecode(token);
      const userRole = decodedToken.role;

      // Redireciona de acordo com o 'role' do usuário
      if (userRole === 'admin') {
        console.log('Login de admin bem-sucedido, redirecionando para o painel de admin');
        navigate('/admin'); // Rota para o dashboard de admin
      } else {
        console.log('Login de usuário comum bem-sucedido, redirecionando para o painel de usuário');
        navigate('/dashboard'); // Rota para o dashboard de usuário
      }
    } catch (err) {
      let errorMessage = 'Erro ao tentar fazer login. Tente novamente mais tarde.';

      if (err.response) {
        if (err.response.status === 401) {
          errorMessage = 'Login ou senha incorretos';
        } else if (err.response.status === 404) {
          errorMessage = 'Usuário não encontrado.';
        } else {
          console.error('Erro no login:', err.response.data);
        }
      } else if (err.request) {
        console.error('Erro de rede:', err.request);
        errorMessage = 'Erro de rede. Verifique sua conexão.';
      } else {
        console.error('Erro na configuração do login:', err.message);
      }

      setError(errorMessage);
    }
  };

  const handleLoginChange = (e) => {
    const value = e.target.value;
    if (isCPF(value)) {
      setLogin(formatCPF(value));
    } else {
      setLogin(value); // Permite entrada de e-mail sem formatação
    }
  };

  return (
    <div className="container">
      <div className="box">
        <h1 className="title">W-GAMES CURITIBA</h1>
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <input
              type="text"
              id="login"
              value={login}
              onChange={handleLoginChange}
              placeholder="Digite seu CPF ou Email"
              required
            />
          </div>
          <div className="input-group">
            <div style={{ position: 'relative' }}>
              <input
                type={mostrarSenha ? 'text' : 'password'}
                id="senha"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
                placeholder="Digite sua senha"
                required
              />
              <span
                onClick={() => setMostrarSenha(!mostrarSenha)}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                  fontSize: '1.2rem',
                  color: mostrarSenha ? '#0072ff' : 'rgba(0, 114, 255, 0.5)',
                }}
              >
                👁️
              </span>
            </div>
          </div>
          {error && <div className="error-message">{error}</div>}
          <button type="submit" className="button">
            Entrar
          </button>
        </form>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '100px' }}>
          <button
            type="button"
            className="button-cadastrar"
            onClick={() => navigate('/cadastro-cliente')}
          >
            Cadastrar
          </button>
          <button
            type="button"
            className="button-esqueci"
            onClick={() => navigate('/esqueci-senha')}
          >
            Esqueci a Senha
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
