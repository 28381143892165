import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Equipamentos from './components/Equipamentos';
import CadastroCliente from './components/CadastroCliente';
import DashboardCliente from './components/DashboardCliente';
import DashboardAdmin from './components/DashboardAdmin';
import ProtectedRoute from './components/ProtectedRoute';
import EquipamentoOrdens from './components/EquipamentoOrdens'; // Importe a página de ordens do equipamento

function App() {
  return (
    <Router>
      <Routes>
        {/* Rota pública de login */}
        <Route path="/" element={<Login />} />

        {/* Rota pública para cadastro de cliente */}
        <Route path="/cadastro-cliente" element={<CadastroCliente />} />

        {/* Rotas protegidas */}
        <Route 
          path="/equipamentos" 
          element={
            <ProtectedRoute>
              <Equipamentos />
            </ProtectedRoute>
          } 
        />
        
        <Route 
          path="/dashboard" 
          element={
            <ProtectedRoute>
              <DashboardCliente />
            </ProtectedRoute>
          } 
        />

        {/* Nova rota para ordens do equipamento */}
        <Route 
          path="/equipamento/:equipamentoId/ordens" 
          element={
            <ProtectedRoute>
              <EquipamentoOrdens />
            </ProtectedRoute>
          } 
        />

        {/* Rota protegida para o painel de admin */}
        <Route 
          path="/admin" 
          element={
            <ProtectedRoute>
              <DashboardAdmin />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
