// src/components/Sidebar.js
import React from 'react';
import './DashboardAdmin';

const Sidebar = ({ setActivePage }) => {
  return (
    <div className="sidebar">
      <h2>Painel Admin</h2>
      <ul>
        <li onClick={() => setActivePage('cadastro')}>Cadastro de Clientes</li>
        <li onClick={() => setActivePage('equipamentos')}>Gerenciar Equipamentos</li>
        <li onClick={() => setActivePage('relatorios')}>Relatórios</li>
      </ul>
    </div>
  );
};

export default Sidebar;
