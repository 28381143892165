import { useEffect, useState } from 'react';
import './DashboardAdmin.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

function GerenciarEquipamentos() {
    const [searchInput, setSearchInput] = useState('');
    const [clienteData, setClienteData] = useState(null);
    const [equipamentos, setEquipamentos] = useState([]);
    const [ordens, setOrdens] = useState([]);
    const [ordemData, setOrdemData] = useState(null);
    const [selectedEquipamento, setSelectedEquipamento] = useState(null);
    const [mostrarFormularioEquipamento, setMostrarFormularioEquipamento] = useState(false);
    const [coresDisponiveis, setCoresDisponiveis] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [equipamentoAtualizado, setEquipamentoAtualizado] = useState({ cor: '', serial: '' });

    const formatPhone = (phone) => {
        return phone.replace(/\D/g, '').replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    };

    const [novoEquipamento, setNovoEquipamento] = useState({
        tipo: '',
        cor: '',
        serial: ''
    });
    const [tiposEquipamentos, setTiposEquipamentos] = useState([]);

    const [novaOrdem, setNovaOrdem] = useState({
        defeito_reclamado: '',
        provavel_causa: '',
        data_entrada: '',
        data_saida: null,
        status: '',
        orcamento: '',
        observacoes: '',
        fotos: ''
    });

    useEffect(() => {
        const fetchTiposEquipamentos = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/equipamentos/tipos`);
                setTiposEquipamentos(response.data);
            } catch (error) {
                console.error('Erro ao buscar tipos de equipamentos:', error);
            }
        };
        fetchTiposEquipamentos();
    }, []);

    useEffect(() => {
        const fetchCoresDisponiveis = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/equipamentos/cores`);
                setCoresDisponiveis(response.data);
            } catch (error) {
                console.error('Erro ao buscar cores disponíveis:', error);
            }
        };
        fetchCoresDisponiveis();
    }, []);

    useEffect(() => {
        const fetchStatusOptions = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/equipamentos/status`);
                setStatusOptions(response.data); // Armazenando os status no estado
            } catch (error) {
                console.error('Erro ao buscar status:', error);
            }
        };
    
        fetchStatusOptions();
    }, []); // A chamada é feita apenas uma vez, quando o componente for montado

    const buscarCliente = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/clientes`, {
                params: { cpfOuTelefone: searchInput },
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setClienteData(response.data);
            buscarEquipamentos(response.data.id);
            setMostrarFormularioEquipamento(true); // Exibe o formulário de equipamento ao encontrar o cliente
        } catch (error) {
            console.error('Erro ao buscar cliente:', error);
            setClienteData(null);
            setMostrarFormularioEquipamento(false); // Oculta o formulário se o cliente não for encontrado
        }
    };

    const buscarEquipamentoPorId = async (equipamentoId) => {
        try {
            const response = await axios.get(`${API_URL}/api/equipamentos/${equipamentoId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setEquipamentoAtualizado({
                cor: response.data.cor,
                serial: response.data.serial
            });
        } catch (error) {
            console.error('Erro ao buscar equipamento por ID:', error);
        }
    };
    
    const buscarEquipamentos = async (clienteId) => {
        try {
            const response = await axios.get(`${API_URL}/api/clientes/${clienteId}/equipamentos`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setEquipamentos(response.data);
        } catch (error) {
            console.error('Erro ao buscar equipamentos:', error);
            setEquipamentos([]);
        }
    };

    const buscarOrdens = async (equipamentoId) => {
        try {
            const response = await axios.get(`${API_URL}/api/equipamentos/${equipamentoId}/ordens`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setOrdens(response.data);
        } catch (error) {
            console.error('Erro ao buscar ordens:', error);
            setOrdens([]);
        }
    };

    const handleSelecionarEquipamento = (equipamentoId) => {
        if (selectedEquipamento === equipamentoId) {
            // Se o equipamento já estiver selecionado, desmarque
            setSelectedEquipamento(null);
            setOrdemData(null); // Limpa as ordens
        } else {
            setSelectedEquipamento(equipamentoId);
            setOrdemData(null); // Reseta a seleção de ordem
            buscarOrdens(equipamentoId); // Busca as ordens do equipamento
    
            // Agora, também vamos buscar os detalhes do equipamento (cor e serial)
            buscarEquipamentoPorId(equipamentoId); // Carrega dados do equipamento
        }
    };
    

    const handleSelecionarOrdem = (ordem) => {
        if (ordemData?.id === ordem.id) {
            setOrdemData(null); // Desmarcar ordem se já estiver selecionada
        } else {
            setOrdemData({ ...ordem });
        }
    };
   
    const adicionarNovaOrdem = async () => {
        if (!novaOrdem.defeito_reclamado || !selectedEquipamento) {
            alert('Por favor, preencha todos os campos obrigatórios.');
            return;
        }

        try {
            await axios.post(
                `${API_URL}/api/equipamentos/${selectedEquipamento}/ordens`,
                novaOrdem,
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }
            );
            alert('Nova ordem adicionada com sucesso!');
            setNovaOrdem({
                defeito_reclamado: '',
                provavel_causa: '',
                data_entrada: '',
                data_saida: '',
                status: '',
                orcamento: '',
                observacoes: '',
                fotos: ''
            });
            buscarOrdens(selectedEquipamento);
        } catch (error) {
            console.error('Erro ao adicionar nova ordem:', error);
            alert('Erro ao adicionar nova ordem.');
        }
    };
    
    const handleAtualizarOrdem = async () => {
        if (!ordemData.defeito_reclamado || !ordemData.status || !selectedEquipamento) {
            alert("Por favor, preencha todos os campos obrigatórios!");
            return;
        }
    
        try {
            // Faz a requisição PUT para atualizar a ordem
            const response = await axios.put(
                `${API_URL}/api/equipamentos/${selectedEquipamento}/ordens/${ordemData.id}`,
                ordemData,
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }
            );
    
            if (response.status === 200) {
                alert("Ordem atualizada com sucesso!");
                // Atualiza o estado local com os dados da ordem atualizada
                setOrdens((prev) =>
                    prev.map((ordem) =>
                        ordem.id === ordemData.id ? { ...ordem, ...ordemData } : ordem
                    )
                );
                setOrdemData(null); // Limpa o formulário
            }
        } catch (error) {
            console.error("Erro ao atualizar ordem:", error);
            alert("Erro ao atualizar ordem.");
        }
    };
    
    
    const handleDeletarOrdem = async (id) => {
        if (!window.confirm("Tem certeza que deseja deletar esta ordem?")) return;
    
        try {
            // Faz a requisição DELETE para deletar a ordem
            const response = await axios.delete(
                `${API_URL}/api/equipamentos/${selectedEquipamento}/ordens/${id}`,
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }
            );
    
            if (response.status === 200) {
                alert("Ordem deletada com sucesso!");
                // Remove a ordem do estado local
                setOrdens((prev) => prev.filter((ordem) => ordem.id !== id));
                setOrdemData(null); // Limpa o formulário se necessário
            }
        } catch (error) {
            console.error("Erro ao deletar ordem:", error);
            alert("Erro ao deletar ordem.");
        }
    };
        
    const adicionarEquipamento = async () => {
        if (!clienteData || !novoEquipamento.tipo || !novoEquipamento.cor) {
            alert('Por favor, preencha todos os campos obrigatórios.');
            return;
        }
        try {
            await axios.post(`${API_URL}/api/clientes/${clienteData.id}/equipamentos`, {
                tipo_equipamento: novoEquipamento.tipo,
                cor: novoEquipamento.cor,
                serial: novoEquipamento.serial
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            buscarEquipamentos(clienteData.id);
            setNovoEquipamento({ tipo: '', cor: '', serial: '' });
            alert('Equipamento adicionado com sucesso!');
        } catch (error) {
            console.error('Erro ao adicionar equipamento:', error);
            alert('Erro ao adicionar equipamento');
        }
    };

    const handleAtualizarEquipamento = async () => {
        if (!equipamentoAtualizado.cor || !selectedEquipamento) {
            alert("Por favor, preencha todos os campos obrigatórios!");
            return;
        }
    
        try {
            // Faz a requisição PUT para atualizar o equipamento
            const response = await axios.put(
                `${API_URL}/api/equipamentos/${selectedEquipamento}`,
                equipamentoAtualizado,
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }
            );
    
            if (response.status === 200) {
                alert("Equipamento atualizado com sucesso!");
                // Atualiza o estado local com os dados do equipamento atualizado
                setEquipamentos((prev) =>
                    prev.map((equipamento) =>
                        equipamento.id === selectedEquipamento
                            ? { ...equipamento, ...equipamentoAtualizado }
                            : equipamento
                    )
                );
                setEquipamentoAtualizado({ tipo: '', cor: '', serial: '' }); // Limpa o formulário
                setSelectedEquipamento(null); // Reseta a seleção do equipamento
            }
        } catch (error) {
            console.error("Erro ao atualizar equipamento:", error);
            alert("Erro ao atualizar equipamento.");
        }
    };
        

    const deletarEquipamento = async (equipamentoId) => {
        // Encontrar o equipamento selecionado no array de equipamentos
        const equipamentoSelecionado = equipamentos.find((equipamento) => equipamento.id === equipamentoId);
        
        // Verificar se o equipamento foi encontrado
        if (!equipamentoSelecionado) {
            alert('Equipamento não encontrado.');
            return;
        }
    
        // Garantir que o nome do cliente esteja disponível
        if (!clienteData || !clienteData.nome) {
            alert('Cliente não encontrado.');
            return;
        }
    
        // Mostrar a pergunta de confirmação antes de deletar
        const confirmacao = window.confirm(`Não é possível desfazer, tem mesmo certeza que deseja deletar ${equipamentoSelecionado.tipo_equipamento} de ${clienteData.nome}?`);
    
        if (confirmacao) {
            try {
                // Requisição para deletar o equipamento
                await axios.delete(`${API_URL}/api/equipamentos/${equipamentoId}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                alert('Equipamento deletado com sucesso!');
                setSelectedEquipamento(null); // Remove a seleção do equipamento
                buscarEquipamentos(clienteData.id); // Atualiza a lista de equipamentos
            } catch (error) {
                console.error('Erro ao deletar equipamento:', error);
                alert('Erro ao deletar equipamento');
            }
        } else {
            console.log('Deleção cancelada.');
        }
    };
    
    
    


    return (
        <div className="dashboard-admin">
            {/* Seção de Busca */}
            <div className="search-section">
                <input 
                    type="text" 
                    placeholder="Buscar por CPF ou Telefone" 
                    value={searchInput} 
                    onChange={(e) => setSearchInput(e.target.value)} 
                />
                <button onClick={buscarCliente}>Buscar</button>
            </div>
    
            {/* Conteúdo Principal */}
            <div className="dashboard-content">
                {clienteData && (
                    <div className="cliente-info">
                        <h3>Cliente: {clienteData.nome} {clienteData.sobrenome} {formatPhone(clienteData.telefone)}</h3>
                    </div>
                )}
    
                {equipamentos.length > 0 && (
                    <div className="equipamentos-section">
                        <h4>Equipamentos do Cliente</h4>
                        {equipamentos.map((equipamento) => (
                            <div
                                key={equipamento.id}
                                onClick={() => handleSelecionarEquipamento(equipamento.id)}
                                className={`equipamento-item ${selectedEquipamento === equipamento.id ? 'selected' : ''}`}
                            >
                                {equipamento.tipo_equipamento}
                            </div>
                        ))}
                    </div>
                )}

{selectedEquipamento && (
    <div className="expand-equipamento">
        <h5>Editar Equipamento</h5>
        <div className="form-field">
            <label htmlFor="cor">Cor</label>
            <select
                id="cor"
                value={equipamentoAtualizado.cor}
                onChange={(e) => setEquipamentoAtualizado({ ...equipamentoAtualizado, cor: e.target.value })}
            >
                <option value="">Selecione</option>
                {coresDisponiveis.map((cor) => (
                    <option key={cor} value={cor}>{cor}</option>
                ))}
            </select>
        </div>

        <div className="form-field">
            <label htmlFor="serial">Número de Série</label>
            <input
                id="serial"
                type="text"
                value={equipamentoAtualizado.serial}
                onChange={(e) => setEquipamentoAtualizado({ ...equipamentoAtualizado, serial: e.target.value })}
            />
        </div>
        <button className="btn-atualizar" onClick={handleAtualizarEquipamento}>
    Atualizar Equipamento
</button>

<button
    className="btn-deletar"
    onClick={() => {
        if (window.confirm(`Tem certeza que deseja deletar o equipamento?`)) {
            deletarEquipamento(selectedEquipamento);
        }
    }}
>
    Deletar Equipamento
</button>
    </div>
)}
    
                {ordens.length > 0 && selectedEquipamento && (
                    <div>
                    <h4>Ordens de Serviço</h4>
                    {ordens.map((ordem) => (
                        <div key={ordem.id} className="ordem-item">
                            <button
                                onClick={() => handleSelecionarOrdem(ordem)}
                                className={ordemData?.id === ordem.id ? 'ordem-selected' : ''}
                            >
                                Ordem #{ordem.id}
                            </button>
                            <button
                                className="btn-deletar"
                                onClick={() => handleDeletarOrdem(ordem.id)}
                            >
                                Deletar
                            </button>
                        </div>
                    ))}
                </div>
                )}
    
        {ordemData && (
        <div className="editar-ordem">
            <h4>Editar Ordem</h4>
            <div className="form-field">
                <label htmlFor="defeito">Defeito Reclamado:</label>
                <input 
                    id="defeito"
                    type="text" 
                    placeholder="Descreva o defeito" 
                    value={ordemData.defeito_reclamado} 
                    onChange={(e) => setOrdemData({ ...ordemData, defeito_reclamado: e.target.value })}
                />
            </div>
            <div className="form-field">
                <label htmlFor="provavelCausa">Provável Causa:</label>
                <input 
                    id="provavelCausa"
                    type="text"
                    value={ordemData.provavel_causa} 
                    onChange={(e) => setOrdemData({ ...ordemData, provavel_causa: e.target.value })}
                />
            </div>
            <div className="form-field">
                <label htmlFor="orcamento">Orçamento:</label>
                <input 
                    id="orcamento"
                    type="text"
                    value={ordemData.orcamento} 
                    onChange={(e) => setOrdemData({ ...ordemData, orcamento: e.target.value })}
                />
            </div>
            <div className="form-field">
                <label htmlFor="dataEntrada">Data de Entrada:</label>
                <DatePicker
                    selected={ordemData.data_entrada}
                    onChange={(date) => setOrdemData({ ...ordemData, data_entrada: date })}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Selecione uma data"
                />
            </div>
            <div className="form-field">
                <label htmlFor="dataSaida">Data de Saída:</label>
                <DatePicker
                    selected={ordemData.data_saida}
                    onChange={(date) => setOrdemData({ ...ordemData, data_saida: date })}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Selecione uma data"
                />
            </div>
            <div className="form-field">
                <label htmlFor="status">Status:</label>
                <select
                    id="status"
                    value={ordemData.status}
                    onChange={(e) => setOrdemData({ ...ordemData, status: e.target.value })}
                >
                    <option value="">Selecione o status</option>
                    {statusOptions.map((status, index) => (
                        <option key={index} value={status}>{status}</option>
                    ))}
                </select>
            </div>
            <div className="form-field">
                <label htmlFor="observacoes">Observações:</label>
                <textarea 
                    id="observacoes"
                    value={ordemData.observacoes} 
                    onChange={(e) => setOrdemData({ ...ordemData, observacoes: e.target.value })}
                />
            </div>
            <button onClick={handleAtualizarOrdem}>Atualizar Ordem</button>
        </div>
    )}
    
                {/* Formulário de Equipamento */}
                {mostrarFormularioEquipamento && !selectedEquipamento && (
                    <div className="formulario-equipo">
                        <h3>Cadastrar Equipamento</h3>
                        <div className="form-field">
                            <label htmlFor="tipo">Tipo de Equipamento</label>
                            <select
                                id="tipo"
                                value={novoEquipamento.tipo}
                                onChange={(e) => setNovoEquipamento({ ...novoEquipamento, tipo: e.target.value })}
                            >
                                <option value="">Selecione</option>
                                {tiposEquipamentos.map((tipo) => (
                                    <option key={tipo} value={tipo}>{tipo}</option>
                                ))}
                            </select>
                        </div>
    
                        <div className="form-field">
                            <label htmlFor="cor">Cor</label>
                            <select
                                id="cor"
                                value={novoEquipamento.cor}
                                onChange={(e) => setNovoEquipamento({ ...novoEquipamento, cor: e.target.value })}
                            >
                                <option value="">Selecione</option>
                                {coresDisponiveis.map((cor) => (
                                    <option key={cor} value={cor}>{cor}</option>
                                ))}
                            </select>
                        </div>
    
                        <div className="form-field">
                            <label htmlFor="serial">Número de Série</label>
                            <input
                                id="serial"
                                type="text"
                                value={novoEquipamento.serial}
                                onChange={(e) => setNovoEquipamento({ ...novoEquipamento, serial: e.target.value })}
                            />
                        </div>
    
                        <button onClick={adicionarEquipamento}>Cadastrar Equipamento</button>
                    </div>
                )}
    
                {/* Formulário para adicionar nova ordem */}
                {selectedEquipamento && !ordemData && (
                    <div className="formulario-ordem">
                        <h3>Cadastrar Nova Ordem de Serviço</h3>
                        <div className="form-field">
                            <label htmlFor="defeito_reclamado">Defeito Reclamado:</label>
                            <input
                                id="defeito_reclamado"
                                type="text"
                                value={novaOrdem.defeito_reclamado}
                                onChange={(e) => setNovaOrdem({ ...novaOrdem, defeito_reclamado: e.target.value })}
                            />
                        </div>
                        <div className="form-field">
                            <label htmlFor="provavel_causa">Provável Causa:</label>
                            <input
                                id="provavel_causa"
                                type="text"
                                value={novaOrdem.provavel_causa}
                                onChange={(e) => setNovaOrdem({ ...novaOrdem, provavel_causa: e.target.value })}
                            />
                        </div>
                        <div className="form-field">
                            <label htmlFor="orcamento">Orçamento:</label>
                            <input
                                id="orcamento"
                                type="text"
                                value={novaOrdem.orcamento}
                                onChange={(e) => setNovaOrdem({ ...novaOrdem, orcamento: e.target.value })}
                            />
                        </div>
                        <div className="form-field">
                            <label htmlFor="data_entrada">Data de Entrada:</label>
                            <DatePicker
                                selected={novaOrdem.data_entrada}
                                onChange={(date) => setNovaOrdem({ ...novaOrdem, data_entrada: date })}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Selecione uma data"
                            />
                        </div>
                        <div className="form-field">
                            <label htmlFor="data_saida">Data de Saída:</label>
                            <DatePicker
                                selected={novaOrdem.data_saida}
                                onChange={(date) => setNovaOrdem({ ...novaOrdem, data_saida: date })}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Selecione uma data"
                            />
                        </div>
                        <div className="form-field">
                            <label htmlFor="status">Status:</label>
                            <select
                                id="status"
                                value={novaOrdem.status}
                                onChange={(e) => setNovaOrdem({ ...novaOrdem, status: e.target.value })}
                            >
                                <option value="">Selecione o status</option>
                                {statusOptions.map((status, index) => (
                                    <option key={index} value={status}>{status}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-field">
                            <label htmlFor="observacoes">Observações:</label>
                            <textarea
                                id="observacoes"
                                value={novaOrdem.observacoes}
                                onChange={(e) => setNovaOrdem({ ...novaOrdem, observacoes: e.target.value })}
                            />
                        </div>
                        <button onClick={adicionarNovaOrdem}>Cadastrar Ordem</button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default GerenciarEquipamentos;