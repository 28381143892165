// src/components/Equipamentos.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const Equipamentos = () => {
  const [equipamentos, setEquipamentos] = useState([]);
  const clienteId = 7; // Exemplo, você pode obter o ID dinamicamente

  useEffect(() => {
    axios.get(`${API_URL}/api/clientes/${clienteId}/equipamentos`)
      .then(response => setEquipamentos(response.data))
      .catch(error => console.error('Erro ao buscar equipamentos:', error));
  }, []);

  // Função para atualizar o status do equipamento
  const updateStatus = (equipamentoId, novoStatus) => {
    axios.put(`${API_URL}/api/equipamentos/${equipamentoId}`, { status: novoStatus })
      .then(() => {
        // Atualiza a lista após a mudança de status
        setEquipamentos(equipamentos.map(e => 
          e.id === equipamentoId ? { ...e, status: novoStatus } : e
        ));
      })
      .catch(error => console.error('Erro ao atualizar status:', error));
  };

  return (
    <div>
      <h1>Equipamentos do Cliente {clienteId}</h1>
      <ul>
        {equipamentos.map(equipamento => (
          <li key={equipamento.id}>
            {equipamento.tipo_equipamento} - {equipamento.descricao} - {equipamento.status}
            {/* Adicionando um seletor para alterar o status */}
            <select 
              value={equipamento.status}
              onChange={(e) => updateStatus(equipamento.id, e.target.value)}
            >
              <option value="Em análise">Em análise</option>
              <option value="Aprovado">Aprovado</option>
              <option value="Reprovado">Reprovado</option>
              <option value="Concluído">Concluído</option>
            </select>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Equipamentos;
