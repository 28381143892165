import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './GlobalStyles.css';

const API_URL = process.env.REACT_APP_API_URL;

const EquipamentoOrdens = () => {
  const { equipamentoId } = useParams();  // Obtém o ID do equipamento da URL
  const [equipamento, setEquipamento] = useState(null); // Armazena as informações do equipamento
  const [ordens, setOrdens] = useState([]);
  const [ordemSelecionada, setOrdemSelecionada] = useState(null); // Ordem selecionada
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEquipamento = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/'); // Redireciona para o login se não houver token
        return;
      }

      try {
        // Faz uma requisição para pegar os dados do equipamento
        const response = await axios.get(`${API_URL}/api/equipamentos/${equipamentoId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setEquipamento(response.data); // Armazena o equipamento no estado
      } catch (err) {
        console.error('Erro ao buscar equipamento:', err);
      }
    };

    fetchEquipamento();
  }, [equipamentoId, navigate]);

  useEffect(() => {
    const fetchOrdens = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/'); // Redireciona para o login se não houver token
        return;
      }

      try {
        // Faz uma requisição para pegar as ordens desse equipamento
        const response = await axios.get(`${API_URL}/api/equipamentos/${equipamentoId}/ordens`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOrdens(response.data);
      } catch (err) {
        console.error('Erro ao buscar ordens:', err);
      }
    };

    fetchOrdens();
  }, [equipamentoId, navigate]);

  const handleOrdemClick = (ordemId) => {
    // Se a ordem clicada já estiver selecionada, desmarque
    if (ordemSelecionada && ordemSelecionada.id === ordemId) {
      setOrdemSelecionada(null);  // Desmarca a ordem, removendo os detalhes
    } else {
      // Caso contrário, seleciona a nova ordem e exibe os detalhes
      const ordem = ordens.find(o => o.id === ordemId);
      setOrdemSelecionada(ordem);
    }
  };

  const mostrarDetalhesOrdem = () => {
    if (!ordemSelecionada) return null;

    // Calcula a data de garantia com base na data de saída (90 dias)
    const dataSaida = ordemSelecionada.data_saida ? new Date(ordemSelecionada.data_saida) : null;
    const garantia = dataSaida ? new Date(dataSaida.setDate(dataSaida.getDate() + 90)) : null;

    // Calcula os dias restantes para a garantia
    const hoje = new Date();
    const diasRestantes = garantia ? Math.ceil((garantia - hoje) / (1000 * 60 * 60 * 24)) : null;

    return (
      <div className="order-details">
        <p><strong>Defeito Reclamado:</strong> {ordemSelecionada.defeito_reclamado}</p>
        <p><strong>Provável Causa:</strong> {ordemSelecionada.provavel_causa}</p>
        <p><strong>Data de Entrada:</strong> {new Date(ordemSelecionada.data_entrada).toLocaleDateString('pt-BR')}</p>
        {dataSaida && (
          <p><strong>Data de Saída:</strong> {new Date(ordemSelecionada.data_saida).toLocaleDateString('pt-BR')}</p>
        )}
        <p><strong>Orçamento:</strong> {ordemSelecionada.orcamento}</p>
        <p><strong>Observações:</strong> {ordemSelecionada.observacoes}</p>
        <p><strong>Status:</strong> <span style={{ color: 'red' }}>{ordemSelecionada.status}</span></p>
        {garantia && (
          <>
            <p><strong>Garantia até:</strong> <span style={{ color: 'red' }}>{garantia.toLocaleDateString('pt-BR')}</span></p>
            {diasRestantes > 0 ? (
              <p>Restam {diasRestantes} dias de garantia</p>
            ) : (
              <p style={{ color: 'red' }}>Garantia expirada</p>
            )}
          </>
        )}
      </div>
    );
};

  return (
    <div className="container">
      <div className="box">
        {equipamento ? (
          <h1 className="title-ordem">{equipamento.tipo_equipamento}</h1>
        ) : (
          <h1 className="title-ordem">Carregando...</h1>
        )}
        {equipamento && (
          <h3 className="subtitle">
          <span className="line"><strong>Cor:</strong> {equipamento.cor}</span>
          <span className="line"><strong>Serial:</strong> {equipamento.serial}</span>
        </h3>
        )}

        <h2 className="subtitle">Lista de Ordens</h2>
        <ul className="order-list">
          {ordens.map((ordem) => (
            <li key={ordem.id} className="order-item">
              <button onClick={() => handleOrdemClick(ordem.id)}>
                Ordem #{4520 + parseInt(ordem.id, 10)}
              </button>
            </li>
            
          ))}
        </ul>
        {mostrarDetalhesOrdem()}

        <button className="back-button" onClick={() => navigate(-1)}>Voltar</button>
      </div>
    </div>
  );
};

export default EquipamentoOrdens;
